import React from "react";
import { Grid, useTheme } from "@mui/material";
import { PostsListItem } from "./PostsListItem";

export const PostsList = ({
  posts,
}: {
  posts: Pick<GatsbyTypes.Query["allSanityBlogPost"], "edges">["edges"];
}) => {
  const theme = useTheme();
  const backgroundColor = theme.palette.primary.main;

  return (
    <Grid container spacing={2}>
      {posts.map(({ node }, index) => (
        <Grid item xs={12} sm={6} md={4} key={`post-${index}`} display="flex">
          <PostsListItem
            post={node}
            sx={{
              flexGrow: 1,
              display: "flex",
              position: "relative",
              overflow: "hidden",
              ":before": {
                content: "''",
                position: "absolute",
                width: "210px",
                height: "210px",
                backgroundColor,
                borderRadius: "50%",
                top: "-125px",
                right: "-15px",
                opacity: 0.2,
              },
              ":after": {
                content: "''",
                position: "absolute",
                width: "210px",
                height: "210px",
                backgroundColor,
                borderRadius: "50%",
                top: "-85px",
                right: "-95px",
                opacity: 0.4,
              },
            }}
          />
        </Grid>
      ))}
    </Grid>
  );
};
