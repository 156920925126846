import React from "react";
import { Box, Container } from "@mui/material";
import { Layout } from "../containers/Layout";
import { PostsList } from "../components/PostsList";
import { FeaturedPost } from "../components/FeaturedPost";
import { Seo } from "../components/Seo";
import { graphql, PageProps } from "gatsby";

const Blog = ({
  data: { allSanityBlogPost },
}: PageProps<{
  allSanityBlogPost: Pick<GatsbyTypes.Query["allSanityBlogPost"], "edges">;
}>) => {
  const featuredPost = allSanityBlogPost.edges[0].node;
  const posts = allSanityBlogPost.edges.slice(1);

  return (
    <Layout>
      <Seo
        title="ブログ一覧"
        description="オンライン英会話の体験談や疑問、気になるポイントなどをわかりやすくブログにまとめています。"
      />
      <FeaturedPost post={featuredPost} />
      <Box>
        <Container maxWidth="lg" sx={{ py: 8 }}>
          <PostsList posts={posts} />
        </Container>
      </Box>
    </Layout>
  );
};

export default Blog;

export const query = graphql`
  {
    allSanityBlogPost(sort: { fields: publishedAt, order: DESC }) {
      edges {
        node {
          title
          publishedAt
          excerpt {
            children {
              text
            }
          }
          slug {
            current
          }
        }
      }
    }
  }
`;
