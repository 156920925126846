import React from "react";
import { navigate } from "gatsby";
import {
  Card,
  CardActionArea,
  CardContent,
  CardHeader,
  Link,
} from "@mui/material";
import { SxProps } from "@mui/system";
import { useBudoux } from "../../lib/helpers";

export const PostsListItem = ({
  post,
  sx,
}: {
  post: GatsbyTypes.SanityBlogPost;
  sx?: SxProps;
}) => {
  const { title, slug, excerpt } = post;
  const targetLink = `/blog/${slug.current}`;

  return (
    <Card sx={{ ...sx, borderRadius: 4 }} variant="outlined">
      <CardActionArea
        onClick={() => {
          navigate(targetLink);
        }}
        sx={{
          zIndex: 1,
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          justifyContent: "flex-start",
        }}
      >
        <CardHeader
          title={
            <Link
              href={targetLink}
              onClick={(e) => {
                e.preventDefault();
              }}
              color="inherit"
              underline="none"
            >
              <span dangerouslySetInnerHTML={{ __html: useBudoux(title) }} />
            </Link>
          }
          titleTypographyProps={{ variant: "h6" }}
          sx={{
            flexGrow: 1,
            alignItems: "flex-start",
          }}
        />
        <CardContent>{excerpt[0].children[0].text}</CardContent>
      </CardActionArea>
    </Card>
  );
};
